import _ from 'lodash'
import lang from '../langs'

export const loginValidate = (userData) => {
    return new Promise((resolve, reject) => {
        let output = {}

        if (_.isEmpty(userData.email)) {
            output.email = lang('required.email')
        }

        if (_.isEmpty(userData.password)) {
            output.password = lang('required.password')
        }

        if (_.isEmpty(output)) {
            resolve(userData)
        } else {
            reject(output)
        }
    })
}

export const registerValidate = (userData) => {
    return new Promise((resolve, reject) => {
        let output = {}

        if (_.isEmpty(userData.name)) {
            output.name = lang('required.name')
        }

        if (_.isEmpty(userData.email)) {
            output.email = lang('required.email')
        }

        if (_.isEmpty(userData.password)) {
            output.password = lang('required.password')
        } else {
            if( userData.password.length < 8 ) {
                output.password = 'Your password must be greater than 8 chars or equal with 8 chars'
            }
        }

        if ( userData.password !== userData.repassword ) {
            output.repassword = lang('wrong.password_matched')
        }

        if (_.isEmpty(output)) {
            resolve(userData)
        } else {
            reject(output)
        }
    })
}

export const forgotValidate = (userData) => {
    return new Promise((resolve, reject) => {
        let output = {}

        if (_.isEmpty(userData.email)) {
            output.email = lang('required.email')
        }

        if (_.isEmpty(output)) {
            resolve(userData)
        } else {
            reject(output)
        }
    })
}


export const resetPasswordValidate = (userData) => {
    return new Promise((resolve, reject) => {
        let output = {}

        if (_.isEmpty(userData.new)) {
            output.new = lang('required.new_password')
        } else {
            if( userData.new.length < 8 ) {
                output.new = lang('invalid.password_chars')
            }
        }

        if (_.isEmpty(userData.confirm)) {
            output.confirm = lang('required.new_password_confirmation')
        }

        if (!_.isEmpty(userData.new) && !_.isEmpty(userData.confirm)) {
            if (userData.new != userData.confirm) {
                output.confirm = lang('wrong.password_matched')
            }
        }

        if (_.isEmpty(output)) {
            resolve(userData)
        } else {
            reject(output)
        }
    })
}

