import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CryptoJS from "crypto-js"
import { Link as GLink, navigate } from "gatsby"
import _, { isNil, isEmpty, has, isObject } from 'lodash'
import lang from '../../langs'
import { connect } from 'react-redux'
import { loginUser, resendVerification } from '../../actions/auth';
import { loginValidate } from '../../validations/authValidate';
import { createNotification, pageTitle } from '../../utils/helpers'
import Layout from '../layout'
import SEO from "../seo"
import Logo from "../Common/LogoDark"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loading:false,
            errors: {},
            message:'',
            messageType:''
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const loc = this.props.location.search
        const states = this.props.location.state

        if( has(states,'resetSuccess') ) {
            if(states.resetSuccess === 'true' ) {
                this.setState({
                    messageType:'success',
                    message:'Great! You just successfully set up new password, please login'
                })
            }
        }
        
        if( !isNil(loc) && !isEmpty(loc) ) {
            const d = new URLSearchParams(loc)

            if( d.get('utm_success') && d.get('utm_success') === 'true' ) {
                this.setState({
                    messageType:'success',
                    message:'Great! Registration is successfully done! Please check your email then follow the instruction on it'
                })
            }

            if( d.get('utm_activated') && d.get('utm_activated') === 'yes' ) {
                this.setState({
                    messageType:'success',
                    message:'Great! Your email has been verified by our System, please log in with your email and password'
                })
            }

            if( d.get('utm_notfound') && d.get('utm_notfound') === 'yes' ) {
                this.setState({
                    messageType:'warning',
                    message:'Sorry! Unable verify your account, the account had verified by Our system, Please login here.'
                })
            }

            if( d.get('utm_exception') && d.get('utm_exception') !== '' ) {
                const words = CryptoJS.enc.Base64.parse( d.get('utm_exception') )
                this.setState({
                    messageType:'danger',
                    message:isObject(words) ? 'Sorry! Unable verify your account, maybe had verified or something went wrong!' : words
                })
            }
        }
        if (this.props.auth.isAuthenticated) {
            navigate('/user/dashboard');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            navigate('/user/dashboard');
        }
    }

    onResend(code, e) {
        MySwal.fire({
            title: 'Resend...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              MySwal.showLoading();
              this.props.resendVerification(code).then(res=>{
                  MySwal.fire(
                    'Success!',
                    'New an email verification is sent. Please check your email now!',
                    'success'
                  )
                  this.setState({
                      message:'',
                      messageType:''
                  })
              }).catch(err=>{
                MySwal.fire(
                  'Sorry!',
                  has(err.response.data, 'messages') ? err.response.data.messages : 'Something unknown error!',
                  'error'
                )
              })
            }
          })
    }

    async onSubmit(e) {
        e.preventDefault();
        const userData = {
            email: this.state.username,
            password: this.state.password
        };

        try {
            await loginValidate(userData)
        } catch (err) {
            this.setState({ errors: err })
            createNotification('error', _.map(err, (val) => { return val }).join("\n\n\n"))
            return false;
        }

        this.setState({loading:true}, ()=>{
            this.props.loginUser(userData)
                .then(res => {
                    const urlParams = new URLSearchParams(this.props.location.search);
                    if( urlParams.get('red') && urlParams.get('red') == 'message' ) {
                        if( urlParams.get('box_id') ) {
                            navigate(`/user/message?box_id=${urlParams.get('box_id')}`);
                        } else {
                            navigate('/user/message');
                        }
                        
                    } else if ( urlParams.get('red') && urlParams.get('red') == 'upgrade' ) {
                        if(  urlParams.get('referrer') && urlParams.get('referrer') == 'landing' ) {
                            navigate('/user/cart');
                        } else {
                            navigate('/user/upgrade');
                        }
                       
                    }
                   // createNotification('success', lang('success.login'))
                })
                .catch(err => {
                    if (err && typeof err.response !== undefined) {
                        const error = err.response
                        if (_.has(error, 'status') && (error.status == 400 ||
                            error.status == 401 ||
                            error.status == 422)) {
                            createNotification('warning', error.data.message)
                        } else if( _.has(error, 'status') && error.status == 405) {
                                const resendCode = error.data.resend_code
                                this.setState({
                                    messageType:'info',
                                    message:<span>If you have not received an email verification, click <a href="#" onClick={this.onResend.bind(this, resendCode)}>here</a> to resend again</span>
                                })
                        } else {
                            createNotification('error', lang('error.server_unknown'))
                        }
                    } else {
                        createNotification('error', lang('error.server_unknown'))
                    }
                    setTimeout(() => {
                        this.setState({
                            loading:false
                        })
                    }, 2000);
                })
        })
        
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });

        if (this.state.errors) {
            if (this.state.email && this.state.errors.email) {
                this.setState({ errors: { username: null } })
            }
            if (this.state.password && this.state.errors.password) {
                this.setState({ errors: { password: null } })
            }
        }
    }

    render() {
        const { errors, message, messageType } = this.state;
        return (
            <Layout withHeader={false}>
                <SEO title="Login" />
                <div id="bodyLogin" className="">
                    <div style={{minHeight:600}} className="align-items-center align-content-center justify-content-center w-auto-xs bg-white">
                        <div className="row justify-content-md-center">
                            <div className="col-4">
                                <div className="shadow rounded mt-5 p-5 mb-3 align-content-center justify-content-center  align-items-center" style={{width:400}}>
                                    <div className="text-center">
                                        <Logo mode="dark" />
                                    </div>
                                    
                                    <div className="text-color">
                                        <div className="text-uppercase text-muted text-center mb-4 text-sm">
                                            Sign-in with...
                                        </div>

                                        {(message && messageType) && <div role="alert" className={`alert alert-${messageType}`}>
                                            <p>{message}</p>
                                        </div>}

                                        <form name="formLogin" noValidate>
                                            <div className="form-label-group mb-4">
                                                <input
                                                    type="text"
                                                    name="username"
                                                    id="email"
                                                    placeholder="Email"
                                                    className={classnames('form-control form-control-lg', {
                                                        'is-invalid': errors.email
                                                    })}
                                                    onChange={this.onChange}
                                                    value={this.state.username}
                                                />
                                                {errors.email && <div className="invalid-feedback">{errors.email}.</div>}
                                            </div>

                                            <div className="form-label-group mb-4">
                                                <input
                                                    type="password"
                                                    name="password"
                                                    className={classnames('form-control form-control-lg', {
                                                        'is-invalid': errors.password
                                                    })}
                                                    placeholder="Password"
                                                    onChange={this.onChange}
                                                    value={this.state.password}
                                                />
                                                {errors.password && <div className="invalid-feedback">{errors.password}.</div>}
                                            </div>

                                            <div className="d-none custom-control custom-checkbox mr-sm-2 mb-3">
                                                <input type="checkbox" className="custom-control-input" id="rememberMe" />
                                                <label className="custom-control-label" htmlFor="rememberMe">{lang('remember_me')}</label>
                                            </div>

                                            <button type="button" id="loginSubmit" disabled={this.state.loading ? true : false} onClick={this.onSubmit} className="btn btn-danger btn-block btn-lg">{this.state.loading ? 'Loading...' : 'Sign In'}</button>

                                            <div className="my-4 text-center">
                                                Have no account? <GLink className="" to={'/register'}>Register Here</GLink> or <br/> Lost Password? <GLink className="" to={'/forgot-password'}>Click Here</GLink>
                                            </div>

                                            <div className="my-3 text-center">
                                                <GLink className="" to={'/'}>Back to Homepage</GLink>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        

                    </div>
                </div>
            </Layout>
        )
    }
}

Login.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    loaded: state.loadingBar.sectionBar == 1 ? true : false
});

export default connect(mapStateToProps, { loginUser, resendVerification })(Login);
