import React from 'react'
import LoadingBar from 'react-redux-loading-bar'
import { NotificationContainer } from 'react-notifications'

// Auth Component
import Login from '../components/Auth/Login'

const LoginIndex = ({location, pageContext}) => {
    return (
        <>
            <NotificationContainer />
            <LoadingBar scope="sectionBar" />
            <Login location={location} />
        </>
    );
}

export default LoginIndex